import React from 'react';

import { ReportCell } from '../../components';

import { ComponentPriority, MenuReportPriority } from './components/priority';
import { ComponentStatus, MenuReportStatus } from './components/status';
import { ComponentAssigned } from './components/assigned';
import { ReportControlsCell, ReportMitresCell } from '../../components/report-cell/ReportCell';

export const formatData = (reports, onUpdate, profile, threatActions) =>
  reports?.map((report, index, array) => {
    const {
      rec_id,
      component,
      component_team_id,
      component_number,
      status,
      priority,
      user,
      threat,
      countermeasures,
      controls = [],
      mitres = [],
      security_checks,
      authority_type,
      resource_attached,
    } = report;

    const canChangePriority = !!(
      threatActions?.company?.['change-priority']?.allowed
      || threatActions?.team?.['change-priority']?.allowed
      || (
        threatActions?.self?.['change-priority']?.allowed
        && profile?.member_id === user?.id
      )
    );

    const canAssignUser = !!(
      threatActions?.company?.['assign-user']?.allowed
      || (
        threatActions?.team?.['assign-user']?.allowed
        && profile.teams.find(team => team.id === component_team_id)
      )
    );

    const canChangeStatus = !!(
      threatActions?.company?.['change-status']?.allowed
      || threatActions?.team?.['change-status']?.allowed
      || (
        threatActions?.self?.['change-status']?.allowed
        && profile?.member_id === user?.id
      )
    );

    let rowBackGround = authority_type === 'private' ? '#fff4bc' : 'white';
    if (
      (Array.isArray(controls) ? controls : [])?.map((c) => c.code).join(', ') === '-' &&
      status.indexOf('AI') !== -1
    ) {
      rowBackGround = '#f0faef';
    }

    return {
      name: {
        value:
          component_number === array[index - 1]?.component_number ? ' ' : `#${component_number}, ${component}` || 'N/A',
        align: 'left',
        component: ReportCell,
        styles: {
          verticalAlign: 'baseline',
        },
      },
      threat: {
        value: threat || 'N/A',
        align: 'left',
        component: ReportCell,
        styles: {
          background: '#FFFBED',
          verticalAlign: 'baseline',
        },
      },
      countermeasures: {
        value: countermeasures || 'N/A',
        align: 'left',
        component: ReportCell,
        styles: {
          verticalAlign: 'baseline',
        },
      },
      control: {
        value: controls,
        align: 'left',
        component: ReportControlsCell,
        styles: {
          verticalAlign: 'baseline',
        },
      },
      mitres: {
        value: mitres,
        align: 'left',
        component: ReportMitresCell,
        styles: {
          verticalAlign: 'baseline',
        },
      },
      security_checks: {
        value: security_checks || 'N/A',
        align: 'left',
        component: ReportCell,
        styles: {
          verticalAlign: 'baseline',
        },
      },
      priority: {
        component: () => (
          <MenuReportPriority
            reportId={rec_id}
            disabled={!canChangePriority}
            current={priority}
            onSuccess={onUpdate}
          >
            <ComponentPriority value={priority} />
          </MenuReportPriority>
        ),
        styles: {
          paddingTop: '12px !important',
          verticalAlign: 'top',
        },
      },
      assigned: {
        component: () => (
          <ComponentAssigned reportId={rec_id} user={user} disabled={!canAssignUser} onSuccess={onUpdate} />
        ),
        styles: {
          paddingTop: '12px !important',
          verticalAlign: 'top',
        },
      },
      status: {
        value: status || 'N/A',
        align: 'left',
        component: () => (
          <MenuReportStatus
            reportId={rec_id}
            disabled={!canChangeStatus}
            current={status}
            onSuccess={onUpdate}
          >
            <ComponentStatus value={status} />
          </MenuReportStatus>
        ),
        styles: {
          paddingTop: '12px !important',
          verticalAlign: 'top',
        },
      },
      data: {
        ...report,
        enable: true,
        rowBackGround: rowBackGround,
      },
      id: index,
    };
  });
