import styled from 'styled-components';
import branding from '../../../../branding';

export const ReportCellWrapper = styled.div`
  position: relative;
  overflow-y: hidden;
  display: flex;
  width: 100%;
  min-width: 160px;
  min-height: ${({ isExpand }) => (isExpand ? 'fin-content' : '120px')};
  max-height: ${({ shouldTruncate }) => shouldTruncate && '126px'};
  justify-content: flex-start;
  align-items: flex-start;
  padding: 12px;
  max-width: 320px;
  flex-direction: column;
`;

export const Title = styled.div`
  font-weight: 700;
  font-size: 12px;
  line-height: 16px;
  color: #0e1a41;
  margin-bottom: 8px;

  ${({ shouldTruncate }) =>
    shouldTruncate &&
    `
       -webkit-box-orient: vertical;
  display: block;
  display: -webkit-box;
  overflow: hidden !important;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
      `}
`;

export const Description = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 16px;
  color: #0e1a41;
  margin-bottom: 8px;
  max-width: 320px;

  ${({ shouldTruncate, shadow }) =>
    shouldTruncate &&
    `
       -webkit-box-orient: vertical;
  display: block;
  display: -webkit-box;
  overflow: hidden !important;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
      `}

  ${({ shadow }) =>
    shadow &&
    `
    position: absolute;
    opacity: 0;
    pointer-events: none;
    `}
`;

export const WrapControls = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 16px;
  color: #0e1a41;
  margin-bottom: 8px;
  max-width: 320px;

  ${({ shouldTruncate, shadow }) =>
    shouldTruncate &&
    `
       -webkit-box-orient: vertical;
  display: block;
  display: -webkit-box;
  overflow: hidden !important;
      `}

  ${({ shadow }) =>
    shadow &&
    `
    position: absolute;
    opacity: 0;
    pointer-events: none;
    `}
`;

export const ShowMore = styled.div`
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  text-decoration-line: underline;
  color: ${() => branding.colors.primary};
  cursor: pointer;
`;

export const SecurityChecksContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

export const SecurityChecksItem = styled.div`
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  text-decoration-line: underline;
  color: ${() => branding.colors.primary};
  background: ${({ background }) => background || branding.colors.lightBlue};
  padding: 2px 5px;
  border-radius: 10px;
  margin-right: 2px;
  margin-bottom: 2px;
  cursor: pointer;
  word-break: break-word;
`;

export const CountermeasuresContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

export const CountermeasuresItem = styled.div`
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  word-break: break-word;
`;

export const CellSimple = styled.div`
  display: inline-block;
  margin: 2px 0;
  padding: 0;
  borderRadius: 10;
`;

export const CellByRow = styled.div`
  display: block;
  margin: 2px 0;
  padding: 0;
  borderRadius: 10;
`;

export const MtButton = styled.button`
  margin: 0 3px;
  border-radius: 6px;
  border: solid 1px transparent;
  line-height: 2px;
  padding: 4px;
  max-height: 12px;
  &:hover {
    background: #cccccc;
    border: solid 1px #eeeeee;
  }
`;

export const MtBg = styled.span`
  background: #f6fff6;
  font-size: 95%;
  padding: 4px;
`;