import React, { memo } from 'react';

import { useParams } from 'react-router';

import { Tab, TabDisabled, TabsWrapper } from './styled';

export const Tabs = memo(({ tabs }) => {
  const { id, accountId, scanId } = useParams();

  return (
    <TabsWrapper data-features-tabs>
      {tabs?.map(({ name, title, to, disabled, isActive, onClick }) => {
        const url = typeof to === 'function' ? to() : to;
        const formattedUrl = url
          .replace(':id', id)
          .replace(':accountId', accountId)
          .replace(':scanId', scanId ?? 'current');

        const TabComponent = disabled ? TabDisabled : Tab;

        return (
          <TabComponent
            key={name}
            isActiveTab={
              isActive ??
              (window.location.pathname === formattedUrl ||
                window.location.pathname.includes(formattedUrl))
            }
            onClick={disabled ? () => {} : onClick}
            disabled={disabled}
            to={formattedUrl}
          >
            {title}
          </TabComponent>
        );
      })}
    </TabsWrapper>
  );
});
