import React, { memo, useCallback, useEffect, useRef } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { useParams, useRouteMatch } from 'react-router';

import {
  Breadcrumbs,
  Loader,
  PipelineHeader,
  Tabs,
  hasExport,
  history,
  useBreadcrumbs,
  useCustomerAccountsPage,
  useOutsideClick,
} from '../../packages';

import {
  appActions,
  appSelectors,
  appService,
  customerDetailsSelectors,
  customerDetailsService,
} from '../../entities';

import { HeaderWithProfile } from '../headers';

import { StartScan } from '../../packages/components/start-scan';

import { Sidebar } from '../sidebar';

import { ScanSelectedAccountsButton } from '../../pages/accounts/components/scan-selected-accounts-button';

import { GlobalLoaderWrapper } from '../../app';

import { AccountServiceModal } from '../onboarding-layouts/modals';

import { PathNames, FeatureSlugs } from '../../consts';

import {
  BreadcrumbsWrapper,
  CSVExportButton,
  Content,
  ExportLabel,
  ExportsWrapper,
  JSONExportButton,
  LayoutWrapper,
  LeftSidebar,
  PipelineContent,
  TabsWrapper,
  Toolbar,
  Wrapper,
} from './styled';
import { AddAccountModal, AddCustomerModal } from './modals';
import { useExport } from './useExport';
import SidebarProfile from '../sidebar-profile';
import SidebarThreatModeling from '../sidebar-threat-modeling';

export const PageLayout = memo(({ children, tabs, withoutSidebar }) => {
  const pipelineData = useSelector(appSelectors.getPipelineData);
  const params = useParams();

  if (process.env.PPTOOL === 'T') {
    const { path } = useRouteMatch();
    const ppFeature = path === PathNames.ppThreadModeling ? FeatureSlugs.ThreatModelling : params.ppFeature;

    return (
      <Wrapper>
        <LayoutWrapper>
          <PipelineContent>
            <PipelineHeader ppToolToken={params.ppToolToken} ppFeature={ppFeature} pipelineData={pipelineData} />
            {children}
          </PipelineContent>
        </LayoutWrapper>
      </Wrapper>
    );
  }

  const dispatch = useDispatch();
  const { accountId } = params;
  const breadcrumbsItems = useBreadcrumbs();
  const sidebarRef = useRef(null);
  const headerRef = useRef(null);

  const exportJSON = useExport('json');
  const exportCSV = useExport('csv');

  const isProfilePage = useRouteMatch(PathNames.profile);
  const isPageThreatModeling = useRouteMatch(PathNames.threatModeling);

  const isSidebarOpen = useSelector(appSelectors.getIsSidebarOpen);

  const isHomePage =
    history.location.pathname.replaceAll('/', '') ===
    PathNames.home.replaceAll('/', '');

  const { availableScans, isUnlimited } = useSelector((state) =>
    customerDetailsSelectors.getAvailableScansCount(state, accountId)
  );
  const currentMenuAccount = useSelector((state) =>
    appSelectors.getMenuAccountById(state, accountId)
  );
  const currentAccount = useSelector((state) =>
    customerDetailsSelectors.getAccountById(state, accountId)
  );

  const onScanClickHandler = useCallback(() => {
    dispatch(customerDetailsService.scanSelectedAccounts([Number(accountId)]));
  }, [accountId]);

  const { isLayoutReady } = useSelector(appSelectors.getAppData);

  const closeSidebarHandler = useCallback(() => {
    dispatch(appActions.setIsSidebarOpen(false));
  }, []);

  useOutsideClick([sidebarRef, headerRef], closeSidebarHandler);

  useEffect(() => {
    if (!isLayoutReady) {
      dispatch(appService.initAppLayout(params));
    }
  }, []);

  if (!isLayoutReady) {
    return (
      <GlobalLoaderWrapper>
        <Loader />
      </GlobalLoaderWrapper>
    );
  }

  return (
    <Wrapper>
      <HeaderWithProfile headerRef={headerRef} isHomePage={isHomePage} />
      <LayoutWrapper>
        {!withoutSidebar && (
          <LeftSidebar ref={sidebarRef} isSidebarOpen={isSidebarOpen}>
            {isProfilePage && <SidebarProfile />}
            {isPageThreatModeling && <SidebarThreatModeling />}
            {!isProfilePage && !isPageThreatModeling && <Sidebar closeSidebar={closeSidebarHandler} />}
          </LeftSidebar>
        )}
        <Content>
          {!!breadcrumbsItems.length && (
            <BreadcrumbsWrapper>
              <Breadcrumbs items={breadcrumbsItems} />
            </BreadcrumbsWrapper>
          )}
          {(!!currentAccount || !!currentMenuAccount) && (
            <StartScan
              pipelineData={pipelineData}
              currentAccount={currentAccount || currentMenuAccount}
              onScanClick={onScanClickHandler}
              isUnlimited={isUnlimited}
              availableScans={availableScans}
            />
          )}
          {(!!tabs?.length || hasExport()) && (
            <Toolbar>
              {!!tabs?.length && (
                <TabsWrapper>
                  <Tabs tabs={tabs} />
                </TabsWrapper>
              )}
              {useCustomerAccountsPage() && <ScanSelectedAccountsButton />}
              {hasExport() && (
                <ExportsWrapper>
                  <ExportLabel>Export to</ExportLabel>
                  <CSVExportButton onClick={exportCSV}>CSV</CSVExportButton>
                  <JSONExportButton onClick={exportJSON}>JSON</JSONExportButton>
                </ExportsWrapper>
              )}
            </Toolbar>
          )}
          {children}
        </Content>
        <AddAccountModal />
        <AddCustomerModal />
        <AccountServiceModal />
      </LayoutWrapper>
    </Wrapper>
  );
});
