import React from 'react';
import { NavLink } from 'react-router-dom';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { PathNames } from '../../../../consts';
import branding from '../../../../branding';

const Header = (props) => {
  const { rightComponent, style } = props;
  const history = useHistory();

  const isDashbordTab = history.location.pathname === PathNames.threatModeling;
  const isDiagramsTab = history.location.pathname === PathNames.diagrams;
  const isThreatsTab = history.location.pathname.startsWith(PathNames.threats);
  const isComponentTypesTab = history.location.pathname.startsWith(PathNames.componentTypes);

  return (
    <div style={ style }>
      <div
        style={{
          display: 'inline-block',
          fontSize: 16,
          color: branding.colors.primary,
          padding: '4px 6px',
          background: '#fff',
          borderRadius: 10,
        }}
      >
        Threat modeling
      </div>
      <div style={{ 
          display: 'flex',
          flexWrap: "wrap",
          rowGap: 16,
          alignItems: 'center',
          justifyContent: 'space-between',
          margin: '25px 0 18px 0'
        }}>
        <WrapTabs>
          <NavLink to={PathNames.threatModeling} data-active={isDashbordTab && '1'}>
            Dashboard
          </NavLink>
          <NavLink to={PathNames.diagrams} data-active={isDiagramsTab && '1'}>
            Diagrams
          </NavLink>
          {/* {console.log('00000', {PathNames})} */}
          <NavLink to={PathNames.threats} data-active={isThreatsTab && '1'}>
            Threats
          </NavLink>
          <NavLink to={PathNames.componentTypes} data-active={isComponentTypesTab && '1'}>
            Component Types
          </NavLink>
        </WrapTabs>
        <div>{rightComponent}</div>
      </div>
    </div>
  );
};

export default Header;

const WrapTabs = styled.div`
  height: 40px;
  border-radius: 8px;
  background: #fff;

  a {
    display: inline-block;
    text-decoration: none;
    cursor: pointer;
    padding: 8px;
    min-width: 140px;
    text-align: center;
    overflow: hidden;
    border-radius: 8px;
    transition: all 0.2s;

    &[data-active='1'] {
      color: #fff;
      background: ${branding.colors.primary};
      pointer-events: none;
    }
  }
`;
