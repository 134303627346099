import * as yup from 'yup';

const textRequired = 'This field is required.';

const defaultSchema = {
  slug: yup.string().required(textRequired),
  name: yup.string().required(textRequired),
  service: yup.string().oneOf(['aws', 'azure', 'other']).required(textRequired),
  company: yup.object({ id: yup.number() }).nullable(),
  last_threats_update: yup.date(),
  diagrams_keywords: yup.array().of(yup.object({ id: yup.number(), label: yup.string() })),
  text_keywords: yup.array().of(yup.object({ id: yup.number(), label: yup.string() })),
  text_direct_keywords: yup.array().of(yup.object({ id: yup.number(), label: yup.string() })),
};

export const defaultValidationSchema = yup.object(defaultSchema);
