import { useCallback, useEffect, useMemo, useState } from 'react';
import IconArrowLeftShort from '../../../../packages/icons/IconArrowLeftShort';
import { Checkmarks } from '../../../../packages/controls/checkmarks';
import branding from '../../../../branding';
import { useURLQueryParam } from '../../../../packages/utils/router';

const useHeaders = (components, controls) => {
  return useMemo(
    () => [
      {
        id: 'name',
        numeric: false,
        disablePadding: false,
        label: <HeaderComponent title='Component' data={components} param='components' primary />,
        align: 'left',
      },
      {
        id: 'threat',
        numeric: false,
        disablePadding: false,
        label: 'Threat',
        align: 'left',
      },
      {
        id: 'countermeasures',
        numeric: false,
        disablePadding: false,
        label: 'Countermeasures',
        align: 'left',
      },
      {
        id: 'control',
        numeric: false,
        disablePadding: false,
        label: <HeaderComponent title='Controls' data={controls} param='controls' />,
        align: 'left',
      },
      {
        id: 'mitres',
        numeric: false,
        disablePadding: false,
        label: 'MITRE ATT&CK',
        align: 'left',
      },
      {
        id: 'security_checks',
        numeric: false,
        disablePadding: false,
        label: 'Applicable Security Checks',
        align: 'left',
      },
      {
        id: 'priority',
        numeric: false,
        disablePadding: false,
        label: 'Priority',
        align: 'left',
      },
      {
        id: 'assigned',
        numeric: false,
        disablePadding: false,
        label: 'Assigned User',
        align: 'left',
      },
      {
        id: 'status',
        numeric: false,
        disablePadding: false,
        label: 'Status',
        align: 'left',
      },
    ],
    [JSON.stringify(components), JSON.stringify(controls)]
  );
};

export default useHeaders;

const HeaderComponent = (props) => {
  const { title, param, data = [], primary = false } = props;
  const t = useMemo(() => Date.now(), []);
  const [active, setActive] = useState(false);
  const [value, setValue] = useState([]);
  const { value: valueStr, onChange: onChangeParam } = useURLQueryParam(param);

  useEffect(() => {
    if (valueStr) {
      setValue(
        valueStr.split(',').map((i) => {
          let v = parseInt(i);
          return Number.isNaN(v) ? i : v;
        })
      );
    }
  }, [valueStr]);

  const options = useMemo(
    () => [
      {
        id: -1,
        label: 'All',
        is_present: true,
      },
      ...data.map((i) => ({
        id: i.value,
        title: i.title,
        label: <span style={{ color: !i.is_present ? 'grey' : 'unset' }}>{i.title}</span>,
        // is_present: i.is_present,
      })),
    ],
    // .filter((i) => (!primary ? i.is_present : true))
    [data]
  );

  const renderValue = useCallback(
    (selected) => {
      const labels = options.filter((i) => (selected || []).includes(i.id)).map((i) => i.title);
      return labels.join(', ');
    },
    [options]
  );

  const onChange = useCallback((e, component) => {
    const { value } = e.target;

    if (component?.props.value === -1) {
      setValue([]);
    } else {
      setValue(value.filter((i) => i !== -1));
    }
  }, []);

  const onApply = useCallback(() => {
    setActive(false);
    onChangeParam(value.join(','));
  }, [onChangeParam, value]);

  return (
    <div
      style={{
        maxWidth: 250,
        display: 'flex',
        alignItems: 'center',
        cursor: 'pointer',
        color: branding.colors.primary,
      }}
      onClick={() => setActive(true)}
    >
      {active ? (
        <Checkmarks
          style={{ height: 40, margin: 0 }}
          label=''
          options={options}
          open
          multiple
          value={value.length ? value : [-1]}
          onChange={onChange}
          renderValue={renderValue}
          onClose={(e) => {
            e.stopPropagation();
            onApply();
          }}
        />
      ) : (
        <>
          <div>
            {title} <span style={{ fontWeight: 700 }}>{!!value.length ? `(${value.length})` : ''}</span>
          </div>
          <IconArrowLeftShort
            style={{ transform: `rotate(${active ? 90 : 270}deg) scale(0.8)`, position: 'relative', top: -2 }}
          />
        </>
      )}
    </div>
  );
};
