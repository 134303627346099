import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { Button, Checkbox, Form, history, SecondaryButton, TextField } from '../../../packages';

import { ButtonsWrapper, checkboxStyle, InputField, ProfileFormWrapper } from './styled';
import { defaultValidationSchema } from './validate';
import { Checkmarks } from '../../../packages/controls/checkmarks';
import {
  threatModelingActions,
  threatModelingApi,
  threatModelingSelectors,
  threatModelingService,
} from '../../../entities';
import { NotificationManager } from 'react-notifications';
import { parseError } from '../../../packages/utils/parse';
import { PageLayout } from '../../../layouts';
import { DiagramsPageWrapper, FormTitle, PageWrapper, WrapTitle } from '../styled';
import Header from '../../dashboard/components/header';
import { TextareaField } from '../../../packages/components/textarea/TextField';
import { SelectLazyField } from '../../diagram-result/components/add-component-modal/components-form/styled';
import { useLazyList } from '../../../packages/utils/list';
import useSelectLazyField from '../../../packages/utils/useSelectLazyField';
import { CheckboxField } from '../../../packages/components/checkbox-field';
import { TodoList } from '../../../packages/controls/todo-list';
import { sortById } from '../../../packages/utils/sort';
import { PathNames } from '../../../consts';
import { BackIcon } from '../components/delete-button/styled';

const TabThreatEdit = (props) => {
  const {
    match: {
      params: { threatId },
    },
  } = props;
  const isNew = threatId === 'new';
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  const { threatIsLoading, threat } = useSelector(threatModelingSelectors.getThreatModelingData);
  const [keyUpdate, setKeyUpdate] = useState(Date.now());
  useEffect(() => {
    if (!isNew) {
      dispatch(threatModelingService.getThreat(threatId));
    } else {
      dispatch(threatModelingActions.setThreat(null));
    }
  }, [isNew, keyUpdate]);

  const initialValues = useMemo(
    () =>
      threat
        ? {
            code: threat.code,
            name: threat.name,
            description: threat.description,
            countermeasures_processing: threat.countermeasures_processing,
            controls_processing: threat.controls_processing,
            security_checks_processing: threat.security_checks_processing,
            is_draft: threat.is_draft,
            security_checks: threat.security_checks.map((i) => ({ text: i })),
            component_type: threat.component_type
              ? { id: threat.component_type.id, label: threat.component_type.name }
              : null,
            countermeasures: threat.countermeasures
              .map((i) => ({
                id: i.id,
                text: i.description,
                is_draft: i.is_draft,
              }))
              .sort(sortById),
            control_codes_mappings: threat.control_codes_mappings.map((i) => ({ id: i.id, label: i.code })),
          }
        : {
            code: '',
            name: '',
            description: '',
            countermeasures_processing: false,
            controls_processing: false,
            security_checks_processing: false,
            is_draft: false,
            security_checks: [],
            component_type: [],
            countermeasures: [],
            control_codes_mappings: [],
          },
    [threat]
  );

  const validationSchema = defaultValidationSchema;

  const setErrors = useCallback((errors, helpers) => {
    Object.keys(errors).forEach((field) => {
      const val = errors[field];
      const text = Array.isArray(val) ? val.join(' ') : val;
      helpers.setFieldError(field, text);
    });
  }, []);

  const onSubmit = useCallback(
    (values, helpers) => {
      const {
        code,
        name,
        description,
        countermeasures_processing,
        controls_processing,
        security_checks_processing,
        is_draft,
        security_checks,
        component_type,
        countermeasures,
        control_codes_mappings,
      } = values;
      const data = {
        code,
        name,
        description,
        countermeasures_processing,
        controls_processing,
        security_checks_processing,
        is_draft,
        security_checks: security_checks.map((i) => i.text),
        component_type_id: component_type?.id,
        countermeasures: countermeasures.map((i) => ({
          id: i.id,
          description: i.text,
          is_draft: i.is_draft,
        })),
        control_codes_mappings,
      };
      const countermeasuresRemoved = threat
        ? threat.countermeasures
            .filter((i) => !countermeasures.map((i) => i.id).includes(i.id))
            .map((i) => ({ ...i, is_delete: true }))
        : [];
      data.countermeasures = [...data.countermeasures, ...countermeasuresRemoved];

      setLoading(true);

      const request = isNew ? threatModelingApi.createThreat(data) : threatModelingApi.updateThreat(threatId, data);

      request
        .then((resp) => {
          NotificationManager.success(
            isNew ? 'The threat was successfully created.' : 'The threat was successfully updated.'
          );
          if (isNew) {
            history.replace({
              pathname: PathNames.threat.replace(':threatId', resp.id),
            });
          } else {
            setKeyUpdate(Date.now());
          }
        })
        .catch((err) => {
          const dataErr = parseError(err);

          setErrors(dataErr, helpers);

          const text = dataErr?.detail || dataErr?.details || dataErr?.message || 'An error has occurred';
          NotificationManager.error(text);
        })
        .finally(() => setLoading(false));
    },
    [dispatch, isNew, threat, setKeyUpdate]
  );

  const SelectTypesOptions = useSelectLazyField(
    {
      name: 'component_type',
      label: 'Component type',
      request: threatModelingApi.getComponentTypes,
      mapFunc: (i) => ({ id: i.id, label: i.name }),
    },
    { withSearch: true }
  );
  const SelectControlCodes = useSelectLazyField(
    {
      name: 'control_codes_mappings',
      label: 'Control codes mappings',
      request: threatModelingApi.getControlCodes,
      mapFunc: (i) => ({ id: i.id, label: i.code }),
    },
    { multiple: true }
  );

  return (
    <PageLayout withoutSidebar>
      <PageWrapper>
        <Header></Header>
        <WrapTitle>
          <BackIcon onClick={() => history.push(PathNames.threats)} />
          <FormTitle>{isNew ? 'Create threat' : 'Change threat'}</FormTitle>
        </WrapTitle>
        <ProfileFormWrapper>
          <Form
            key={`${threatIsLoading}-${isNew}-${threat?.id}`}
            validationSchema={validationSchema}
            initialValues={initialValues}
            isLoading={loading}
            onSubmit={onSubmit}
          >
            <InputField name='code' label='Code' />
            <InputField name='name' label='Name' />
            <TextField type='textarea' name='description' label='Description' />
            {SelectTypesOptions}
            {SelectControlCodes}
            <TodoList name='security_checks' label='Security checks' />
            <CheckboxField label='Draft' name='is_draft' style={checkboxStyle} />
            <CheckboxField label='Countermeasures processing' name='countermeasures_processing' style={checkboxStyle} />
            <CheckboxField label='Controls processing' name='controls_processing' style={checkboxStyle} />
            <CheckboxField label='Security checks processing' name='security_checks_processing' style={checkboxStyle} />
            <TodoList name='countermeasures' label='Countermeasures' />
            <ButtonsWrapper>
              <div />
              <Button variant='contained' size='medium' type='submit' style={{ width: 140, height: 40 }}>
                {loading ? (threatId ? 'Creating...' : 'Saving...') : threatId ? 'Create' : 'Save'}
              </Button>
            </ButtonsWrapper>
          </Form>
        </ProfileFormWrapper>
      </PageWrapper>
    </PageLayout>
  );
};

export default TabThreatEdit;
