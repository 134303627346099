export const PathNames = {
  welcome: '/',
  login: '/login',
  signUp: '/sign-up',
  verification: '/verification/',
  chooseFeatures: '/choose-features',
  home: '/home',

  profile: '/profile',
  users: '/profile/users',
  teams: '/profile/teams',
  team: '/profile/teams/:teamId',
  features: '/profile/features/',

  confirmation: '/confirmation/',
  confirmationSaasAzure: '/confirmation/saas-api/azure/',
  inviteToCompany: '/invite-to-company/',

  threatModeling: '/threat-modeling/',
  diagrams: '/threat-modeling/diagrams',
  threats: '/threat-modeling/threats/',
  threat: '/threat-modeling/threats/:threatId/',
  componentTypes: '/threat-modeling/component-types',
  componentType: '/threat-modeling/component-types/:componentTypeId/',
  diagramResult: '/threat-modeling/diagrams/:diagramId/',

  oauthRedirect: '/oauth-redirect',
  linkAuth: '/link-auth',
  onboarding: '/onboarding/:feature/',
  pipelineOnboarding: '/pipeline-onboarding/',
  customers: '/:feature/customers',

  customerResults: '/:feature/:id/results',
  customerAccounts: '/:feature/:id/accounts',
  customerScanHistory: '/:feature/:id/scan-history',

  accountOverview: '/:feature/:scanId/:id/account/:accountId',
  standardOverview:
    '/:feature/:scanId/:id/account/:accountId/standard/:standardId',
  policyGroupOverview:
    '/:feature/:scanId/:id/account/:accountId/standard/:standardId/policy-group/:policyGroupId',
  policyDetails:
    '/:feature/:scanId/:id/account/:accountId/standard/:standardId/policy-group/:policyGroupId/policy/:policyId',

  ppOverview: '/cicd/:ppFeature/:ppToolToken',
  ppThreadModeling: '/cicd/thread-modeling/:ppToolToken',
  ppStandardOverview: '/cicd/:ppFeature/:ppToolToken/:standardId',
  ppPolicyGroupOverview:
    '/cicd/:ppFeature/:ppToolToken/:standardId/:policyGroupId',
  ppPolicyDetails:
    '/cicd/:ppFeature/:ppToolToken/:standardId/:policyGroupId/:policyId',
};

export const ScanStatuses = {
  inprogress: 'inprogress',
  completed: 'completed',
};

export const FeatureSlugs = {
  PlatformSecurity: 'platform-security',
  ThreatModelling: 'threat-modeling',
  PipelineSecurity: 'pipeline-security',
};

export const FeaturePrefix = {
  PlatformSecurity: 'platform-security',
  ThreatModelling: 'diagrams',
  PipelineSecurity: 'pipeline-security',
};

export const FeaturePrefixBySlug = {
  [FeatureSlugs.PlatformSecurity]: FeaturePrefix.PlatformSecurity,
  [FeatureSlugs.ThreatModelling]: FeaturePrefix.ThreatModelling,
  [FeatureSlugs.PipelineSecurity]: FeaturePrefix.PipelineSecurity,
};

export const STORAGE_REDIRECT_KEY = 'confirm-redirect';
export const STORAGE_REDIRECT_EXPIRE_KEY = 'confirm-redirect-expire';
