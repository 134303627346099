import React, { memo } from 'react';
import { Label, WelcomeLabel } from './styled'; 
import branding from '../../branding';

export const WelcomeTxt = memo(() => {
  return (
    <>
      <div className="welcome-section"> 
        <WelcomeLabel>Welcome to {branding.name}!</WelcomeLabel>
        <Label>ReThink security with AI-powered solutions from Aristiun.</Label>
      </div>

      <div className="key-solutions"> 
        <h2>Experience the Power of AI for Security:</h2>
        <p>Protect your applications, cloud environments, and CI/CD pipelines with our cutting-edge AI.</p>

        <h3 style={{ fontSize: '20px', fontWeight: '600', marginBottom: '10px', color: '#222222' }}>Aribot: AI for Threat Modeling, CSPM, & ASPM</h3> 
        <ul style={{ marginLeft: '35px' }}> 
          <li>Drastically reduce threat modeling effort (by 80%) and uncover more attack vectors (40% more)</li>
          <li>Streamline compliance (NIST, MITRE ATT&CK, and more)</li>
          <li>Secure cloud environments (Azure, AWS) and applications with AI</li>
          <li>Integrate security directly into your CI/CD pipelines</li>
        </ul>

        <h3 style={{ fontSize: '20px', fontWeight: '600', marginBottom: '10px', color: '#222222' }}>Beyond Aribot</h3>
        <ul style={{ marginLeft: '35px' }}> 
          <li>Complete visibility with AI analytics to pinpoint security gaps</li>
          <li>Simplified compliance for 40+ frameworks (NIST, NIS2, ISO 27001, etc.)</li>
          <li>Proactive risk management to mitigate threats</li> 
        </ul>
      </div> 

      <div className="call-to-action">
        <h2>Onboard directly to development environments:</h2>
        <Label>Explore integration options of {branding.name} with your Azure DevOps, GitHub, or Jenkins pipelines: <a href="https://www.aristiun.com/" target="_blank">{branding.name} - Aristiun Aribot</a></Label>
      </div>

      <div className="benefits">
        <h2>After onboarding, you can:</h2>
        <ul style={{ marginLeft: '35px' }}> 
          <li><strong>Aribot AI for Threat Modeling:</strong> Drastically reduce threat modeling effort (by 80%), uncover more attack vectors (40% more), and streamline compliance (NIST, MITRE ATT&CK, and more).</li>
          <li><strong>Aribot AI for CSPM:</strong> Secure cloud environments (Azure, AWS) with AI-powered insights.</li> 
          <li><strong>Aribot AI for ASPM:</strong> Protect your applications throughout development and integrate security directly into your CI/CD pipelines.</li>
          <li><strong>Beyond Aribot:</strong> Get complete visibility with AI analytics to pinpoint security gaps, simplified compliance across 40+ frameworks (NIST, NIS2, ISO 27001, DORA, AI Act), and proactive risk management.</li>
        </ul>
      </div>

      <div className="support-resources">
        <h2>Need Help?</h2>
        <Label>Check out our onboarding and support videos: <a href="https://youtube.com/playlist?list=PLnW8hs1GG7Xr9vbgvRch-5h7i-kF2Cvp-" target="_blank">{branding.name} - powered by {branding.poweredBy}</a></Label>
      </div>
    </>
  );
});
