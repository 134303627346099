import { useParams } from 'react-router';

import { useSelector } from 'react-redux';

import { FeaturePrefix, PathNames } from '../../consts';

import { standardSelectors } from '../../entities/standard/selectors';
import { customerDetailsSelectors } from '../../entities/customer-details/selectors';
import { policyGroupSelectors } from '../../entities/policy-group/selectors';
import { appSelectors } from '../../entities/app/selectors';
import { threatModelingSelectors } from '../../entities/threat-modeling/selectors';
import { getCurrentFeaturePrefix } from './getCurrentFeaturePrefix';

export const useBreadcrumbs = () => {
  const {
    id,
    accountId,
    policyGroupId,
    standardId,
    scanId,
    policyId,
    diagramId,
  } = useParams();
  const currentCustomer = useSelector(appSelectors.getCustomer);
  const currentDiagram = useSelector(threatModelingSelectors.getDiagram);
  const currentAccount = useSelector((state) =>
    customerDetailsSelectors.getAccountById(state, accountId)
  );
  const selectedAccount = useSelector(appSelectors.getAccount);
  const currentMenuAccount = useSelector((state) =>
    appSelectors.getMenuAccountById(state, accountId)
  );
  const currentStandard = useSelector((state) =>
    standardSelectors.getStandardById(state, standardId)
  );
  const currentPolicyGroup = useSelector((state) =>
    standardSelectors.getPolicyGroupById(state, policyGroupId)
  );
  const currentPolicy = useSelector((state) =>
    policyGroupSelectors.getPolicyById(state, policyId)
  );
  const items = [];

  if (id && currentCustomer) {
    items.push({
      name: currentCustomer?.name,
      to: PathNames.customerResults
        .replace(':feature', getCurrentFeaturePrefix())
        .replace(':id', id),
    });
  }

  if (diagramId && currentDiagram) {
    items.push({
      name: 'Threat modeling',
      to: PathNames.diagrams,
    });
    items.push({
      name: currentDiagram.filename,
      to: PathNames.diagramResult.replace(':diagramId', diagramId),
    });
  }

  if (accountId && (currentAccount || currentMenuAccount || selectedAccount)) {
    const account = currentMenuAccount || currentAccount || selectedAccount;

    items.push({
      name: `${account.service}-${account.name}`,
      to: PathNames.accountOverview
        .replace(':feature', getCurrentFeaturePrefix())
        .replace(':id', id)
        .replace(':scanId', scanId)
        .replace(':accountId', accountId),
    });
  }

  if (standardId && currentStandard) {
    items.push({
      name: currentStandard.name,
      to: PathNames.standardOverview
        .replace(':feature', getCurrentFeaturePrefix())
        .replace(':id', id)
        .replace(':scanId', scanId)
        .replace(':accountId', accountId)
        .replace(':standardId', standardId),
    });
  }

  if (policyGroupId && currentPolicyGroup) {
    items.push({
      name: currentPolicyGroup.name,
      to: PathNames.policyGroupOverview
        .replace(':feature', getCurrentFeaturePrefix())
        .replace(':id', id)
        .replace(':scanId', scanId)
        .replace(':accountId', accountId)
        .replace(':standardId', standardId)
        .replace(':policyGroupId', policyGroupId),
    });
  }

  if (policyId && currentPolicy) {
    items.push({
      name: currentPolicy.name,
      to: PathNames.policyGroupOverview
        .replace(':feature', getCurrentFeaturePrefix())
        .replace(':id', id)
        .replace(':scanId', scanId)
        .replace(':accountId', accountId)
        .replace(':standardId', standardId)
        .replace(':policyGroupId', policyGroupId)
        .replace(':policyId', policyId),
    });
  }

  return items;
};
