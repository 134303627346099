import styled from 'styled-components';

export const CardWrapper = styled.div`
  display: flex;
  flex-direction: column;
  box-shadow: ${({ theme }) => theme.shadows.cardShadow};
  background: ${({ theme }) => theme.colors.secondaryBackground};
  opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};
  border-radius: 8px;
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  height: 50px;
  color: ${({ theme }) => theme.colors.textColor};
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  border-radius: 8px;

  align-items: flex-end;
  padding: 0 32px;
`;

export const Content = styled.div`
  display: flex;
  flex: 1;
`;
