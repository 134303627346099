import IconChainSolid from '../../packages/icons/IconChainSolid';
import IconChainSplitted from '../../packages/icons/IconChainSplitted';
import {
  ComponentName,
  ComponentStatus,
  MenuComponentButton,
  ReportCell,
  SelectComponentButton,
} from './components';
import { ReportControlsCell, ReportMitresCell } from './components/report-cell/ReportCell';


export const formatComponentsData = (components, diagramId, hideMenu = false, onUpdate) =>
  components?.map((component) => {
    const { label, status, id, type_verbose, number, team, resource, manual_data} = component;

    const data = {
      select: { component: SelectComponentButton, align: 'center' },
      index: { value: `${number}`, align: 'left' },
      status: {
        value: status || 'N/A',
        component: ComponentStatus,
        align: 'center',
      },
      name: { value: label || 'N/A', align: 'left', component: ComponentName },
      type: { value: type_verbose || 'N/A', align: 'left' },
      team: { value: team?.name || '-', align: 'left' },
      //flow: { value: 'N/A', align: 'left', component: ComponentName },
      // TODO the specified order keys affects the display rows order!!!
      resource: {
        value: resource || '-',
        align: 'left',
        component: resource ? IconChainSolid : IconChainSplitted,
      },
      data: { ...component, enable: true },
      id,
    };

    if (!hideMenu) {
      data.menu = {
        component: (props) => <MenuComponentButton
          {...props}
          diagramId={diagramId}
          resourceId={resource}
          node_id={manual_data?.id}
          team={team}
          onUpdate={onUpdate}
          />,
        align: 'center',
      };
    }

    return data;
  });

export const formatReportData = (reports) =>
  reports?.map((report, index, array) => {
    const {
      component,
      status,
      threat,
      countermeasures,
      controls,
      mitres,
      security_checks,
      authority_type,
    } = report;

    let rowBackGround = authority_type === 'private' ? '#fff4bc' : 'white';
    if (controls === '-' && status.indexOf('AI') !== -1) {
      rowBackGround = '#f0faef';
    }

    return {
      name: {
        value:
          component === array[index - 1]?.component ? ' ' : component || 'N/A',
        align: 'left',
        component: ReportCell,
        styles: {
          verticalAlign: 'baseline',
        },
      },
      threat: {
        value: threat || 'N/A',
        align: 'left',
        component: ReportCell,
        styles: {
          background: '#FFFBED',
          verticalAlign: 'baseline',
        },
      },
      countermeasures: {
        value: countermeasures || 'N/A',
        align: 'left',
        component: ReportCell,
        styles: {
          verticalAlign: 'baseline',
        },
      },
      control: {
        value: controls || 'N/A',
        align: 'left',
        component: ReportControlsCell,
        styles: {
          verticalAlign: 'baseline',
        },
      },
      mitres: {
        value: mitres || 'N/A',
        align: 'left',
        component: ReportMitresCell,
        styles: {
          verticalAlign: 'baseline',
        },
      },
      security_checks: {
        value: security_checks || 'N/A',
        align: 'left',
        component: ReportCell,
        styles: {
          verticalAlign: 'baseline',
        },
      },
      status: {
        value: status || 'N/A',
        align: 'left',
        component: ReportCell,
        styles: {
          verticalAlign: 'baseline',
        },
      },
      data: {
        ...report,
        enable: true,
        rowBackGround: rowBackGround
      },
      id: index,
    };
  });
