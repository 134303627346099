import React from 'react';
import ReactDOM from 'react-dom';
import { Provider, useSelector } from 'react-redux';

import { Route, Switch } from 'react-router-dom';

import { ThemeProvider } from 'styled-components';
import { ThemeProvider as MuiThemeProvider, createTheme } from '@mui/material';

import { App, GlobalStyle } from './app';
import { NotificationInjector, createStore } from './packages';
import { rootReducer } from './reducer';
import { FeaturePrefix, FeatureSlugs, PathNames } from './consts';
import {
  AccountOverviewPage,
  AccountsPage,
  AuthPage,
  ChooseFeature,
  CustomerResults,
  CustomersPage,
  DiagramResult,
  Diagrams,
  Home,
  LinkAuthPage,
  NotFoundRedirect,
  OAuthRedirectPage,
  OnboardingPage,
  PipelineOnboardingPage,
  PolicyDetailsPage,
  PolicyGroupOverviewPage,
  ScanHistoryPage,
  SignUp,
  StandardOverviewPage,
  Verification,
  Welcome,
} from './pages';
import { theme } from './theme';
import 'react-notifications/lib/notifications.css';
import { RouteWithPermissions } from './RouteWithPermissions';
import { PageProfile } from './pages/profile';
import { PageTeams } from './pages/profile/PageTeams';
import { PageUsers } from './pages/profile/PageUsers';
import PageTeam from './pages/profile/PageTeam';
import { PageFeatures } from './pages/profile/PageFeatures';
import { PageConfirmation } from './pages/confirmation';
import { PageInviteToTeam } from './pages/invite-to-team';
import { PageDashboard } from './pages/dashboard';
import { TabDiagram } from './pages/dashboard/TabDiagram';
import { appSelectors } from './entities';
import TabThreats from './pages/admin-sections/TabThreats';
import TabThreatEdit from './pages/admin-sections/TabThreatEdit';
import TabComponentTypes from './pages/admin-sections/TabComponentTypes';
import TabComponentTypeEdit from './pages/admin-sections/TabComponentTypeEdit';


const store = createStore(rootReducer);
const muiTheme = createTheme();

// const profile = useSelector(appSelectors.getProfile);
//
// const { permissions, role } = profile;
//
// console.log(permissions);

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <MuiThemeProvider theme={{ ...theme, ...muiTheme }}>
          <NotificationInjector />
          <GlobalStyle />
          <App>
            <Switch>
              {/* TODO need to add AuhtRoute */}
              <Route exact path={PathNames.welcome} component={Welcome} />
              <Route exact path={PathNames.signUp} component={SignUp} />
              <Route path={PathNames.verification} component={Verification} />
              <Route
                path={PathNames.confirmation}
                component={PageConfirmation}
              />
              <Route
                path={PathNames.inviteToCompany}
                component={PageInviteToTeam}
              />
              <RouteWithPermissions
                exact
                path={PathNames.diagramResult}
                component={DiagramResult}
                featurePermissions={[FeatureSlugs.ThreatModelling]}
              />
              <Route
                exact
                path={PathNames.chooseFeatures}
                component={ChooseFeature}
              />
              <Route path={PathNames.login} component={AuthPage} />
              <RouteWithPermissions
                path={PathNames.diagrams}
                featurePermissions={[FeatureSlugs.ThreatModelling]}
                component={TabDiagram}
              />
              <RouteWithPermissions
                path={PathNames.threat}
                component={TabThreatEdit}
              />
              <RouteWithPermissions
                path={PathNames.threats}
                component={TabThreats}
              />
              <RouteWithPermissions
                path={PathNames.componentType}
                component={TabComponentTypeEdit}
              />
              <RouteWithPermissions
                path={PathNames.componentTypes}
                component={TabComponentTypes}
              />
              <RouteWithPermissions
                path={PathNames.threatModeling}
                featurePermissions={[FeatureSlugs.ThreatModelling]}
                component={PageDashboard}
              />
              <Route
                exact
                path={PathNames.oauthRedirect}
                component={OAuthRedirectPage}
              />
              <Route
                path={PathNames.pipelineOnboarding}
                component={PipelineOnboardingPage}
              />
              <Route path={PathNames.home} component={Home} />
              <Route path={PathNames.users} component={PageUsers} />
              <Route path={PathNames.team} component={PageTeam} />
              <Route path={PathNames.teams} component={PageTeams} />
              <Route path={PathNames.features} component={PageFeatures} />
              <Route path={PathNames.profile} component={PageProfile} />
              <Route
                exact
                path={PathNames.onboarding}
                component={OnboardingPage}
              />
              <Route exact path={PathNames.linkAuth} component={LinkAuthPage} />
              <RouteWithPermissions
                exact
                path={PathNames.customers.replace(
                  ':feature',
                  FeaturePrefix.PlatformSecurity
                )}
                component={CustomersPage}
                featurePermissions={[FeatureSlugs.PlatformSecurity]}
              />
              <RouteWithPermissions
                exact
                path={PathNames.customers.replace(
                  ':feature',
                  FeaturePrefix.PipelineSecurity
                )}
                component={CustomersPage}
                featurePermissions={[FeatureSlugs.PipelineSecurity]}
              />
              <RouteWithPermissions
                exact
                path={PathNames.customerResults.replace(
                  ':feature',
                  FeaturePrefix.PlatformSecurity
                )}
                component={CustomerResults}
                featurePermissions={[FeatureSlugs.PlatformSecurity]}
              />
              <RouteWithPermissions
                exact
                path={PathNames.customerResults.replace(
                  ':feature',
                  FeaturePrefix.PipelineSecurity
                )}
                component={CustomerResults}
                featurePermissions={[FeatureSlugs.PipelineSecurity]}
              />
              <RouteWithPermissions
                exact
                path={PathNames.customerScanHistory.replace(
                  ':feature',
                  FeaturePrefix.PlatformSecurity
                )}
                component={ScanHistoryPage}
                featurePermissions={[FeatureSlugs.PlatformSecurity]}
              />
              <RouteWithPermissions
                exact
                path={PathNames.customerScanHistory.replace(
                  ':feature',
                  FeaturePrefix.PipelineSecurity
                )}
                component={ScanHistoryPage}
                featurePermissions={[FeatureSlugs.PipelineSecurity]}
              />
              <RouteWithPermissions
                exact
                path={PathNames.accountOverview.replace(
                  ':feature',
                  FeaturePrefix.PlatformSecurity
                )}
                component={AccountOverviewPage}
                featurePermissions={[FeatureSlugs.PlatformSecurity]}
              />
              <RouteWithPermissions
                exact
                path={PathNames.accountOverview.replace(
                  ':feature',
                  FeaturePrefix.PipelineSecurity
                )}
                component={AccountOverviewPage}
                featurePermissions={[FeatureSlugs.PipelineSecurity]}
              />
              <RouteWithPermissions
                exact
                path={PathNames.standardOverview.replace(
                  ':feature',
                  FeaturePrefix.PlatformSecurity
                )}
                component={StandardOverviewPage}
                featurePermissions={[FeatureSlugs.PlatformSecurity]}
              />
              <RouteWithPermissions
                exact
                path={PathNames.standardOverview.replace(
                  ':feature',
                  FeaturePrefix.PipelineSecurity
                )}
                component={StandardOverviewPage}
                featurePermissions={[FeatureSlugs.PipelineSecurity]}
              />
              <RouteWithPermissions
                exact
                path={PathNames.policyGroupOverview.replace(
                  ':feature',
                  FeaturePrefix.PlatformSecurity
                )}
                component={PolicyGroupOverviewPage}
                featurePermissions={[FeatureSlugs.PlatformSecurity]}
              />
              <RouteWithPermissions
                exact
                path={PathNames.policyGroupOverview.replace(
                  ':feature',
                  FeaturePrefix.PipelineSecurity
                )}
                component={PolicyGroupOverviewPage}
                featurePermissions={[FeatureSlugs.PipelineSecurity]}
              />
              <RouteWithPermissions
                exact
                path={PathNames.customerAccounts.replace(
                  ':feature',
                  FeaturePrefix.PlatformSecurity
                )}
                component={AccountsPage}
                featurePermissions={[FeatureSlugs.PlatformSecurity]}
              />
              <RouteWithPermissions
                exact
                path={PathNames.customerAccounts.replace(
                  ':feature',
                  FeaturePrefix.PipelineSecurity
                )}
                component={AccountsPage}
                featurePermissions={[FeatureSlugs.PipelineSecurity]}
              />
              <RouteWithPermissions
                exact
                path={PathNames.policyDetails.replace(
                  ':feature',
                  FeaturePrefix.PlatformSecurity
                )}
                component={PolicyDetailsPage}
                featurePermissions={[FeatureSlugs.PlatformSecurity]}
              />
              <RouteWithPermissions
                exact
                path={PathNames.policyDetails.replace(
                  ':feature',
                  FeaturePrefix.PipelineSecurity
                )}
                component={PolicyDetailsPage}
                featurePermissions={[FeatureSlugs.PipelineSecurity]}
              />
              <Route exact path="*" component={NotFoundRedirect} />
            </Switch>
          </App>
        </MuiThemeProvider>
      </ThemeProvider>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);
