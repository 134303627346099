import { Link } from '@mui/material';
import { styled as muiStyled } from '@mui/material/styles';

export const BreadcrumbLink = muiStyled(Link)(({ theme, color }) => ({
  maxWidth: '200px',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  display: 'inline-block',
  verticalAlign: 'top',
  backgroundColor: theme.colors.secondaryBackground,
  padding: '2px 7px',
  borderRadius: '10px',
  marginBottom: '5px',
  color,
}));
